<template>
  <footer>
    <div class="wrapper">
      <div class="icon">
        <h3>kopis</h3>
        <img src="@/assets/icons/logo/kopi-sore-yellow.svg" alt="0">
        <h3>re</h3>
      </div>

      <div class="content">
        <div class="info">
          <img src="@/assets/icons/email.png" alt="email">
          <a href="https://mailto:official.kopisore@gmail.com">official.kopisore@gmail.com</a>
        </div>

        <div class="info">
          <img src="@/assets/icons/whatsapp.png" alt="WhatsApp">
          <a href="https://wa.me/+6285323777113">+62 853-2377-7113</a>
        </div>

        <div class="info">
          <img src="@/assets/icons/instagram.png" alt="Instagram">
          <a href="https://www.instagram.com/ceritakopisore">ceritakopisore</a>
        </div>

        <div class="info">
          <img src="@/assets/icons/youtube.png" alt="Youtube" class="youtube">
          <a
            href="https://m.youtube.com/watch?embeds_referring_euri=https%3A%2F%2Flinktr.ee%2F&embeds_referring_origin=https%3A%2F%2Flinktr.ee&source_ve_path=MTY0NTA2LDE2NDUwMw&feature=emb_share&v=zbxekUz2aTQ">Kopi
            Sore</a>
        </div>

        <div class="info">
          <img src="@/assets/icons/location.png" alt="Location">
          <a href="https://maps.app.goo.gl/5nR8cWbsb4AqmNgG7">Goropak</a>
        </div>
      </div>

      <div class="copyright">
        <p style="font-size: 0.5em;"><a href="https://www.github.com/faizallmaullana">&copy;2024 | faizallmaullana</a></p>
      </div>
    </div>
  </footer>
</template>


<style>
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: var(--color-gray);
  background-color: var(--color-black);
  overflow: hidden;
}

footer .icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1em;
  margin-bottom: 12px;
}

footer .icon img {
  height: 1.5em;
  transform: translateY(-4px)
}

footer .info {
  display: flex;
  align-self: flex-end;
  justify-content: flex-start;
  color: var(--color-gray);
  margin: 0.5em;
  padding: 0 3em;
}

footer .info img {
  width: 20px;
  object-fit: scale-down;
}

footer .info a {
  padding: 0 1em;
  color: var(--color-gray);
  font-weight: 500;
  transition: 0.3s;
  margin-block: 0;
}

footer .info a:hover {
  color: var(--color-yellow);
  font-size: 1.1em;
}

footer h3 {
  color: var(--color-yellow);
}

footer .wrapper {
  width: 1024px;
}

footer .content {
  border-top: 2px solid var(--color-gray);
  border-bottom: 2px solid var(--color-gray);
  padding: 1em 0;
}

footer .copyright {
  margin: 1em 0 0 0;
}

footer .copyright p {
  text-align: center;
}

footer .copyright a {
  color: var(--color-gray);
}
</style>

<style>
@media screen and (max-width: 1024px) {
  footer .wrapper {
    width: 90vw;
  }

  footer .info {
    padding: 0 1em;
  }
}
</style>